import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Cv()  {

    const data = useStaticQuery(graphql`
    query PdfQuery {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
  }
  `)
const pdf = data.allFile.edges

return ( 
<>
    {pdf.map(({ node }) => {
        return (  <a key={node.id} className="pt5 pt0-ns dim" href={node.publicURL} rel="noopener noreferrer" target="_blank"> 
                    <div className="f7 lh-copy fw4 white-50 ttc mw5 ttu tl tracked-lg pl3">cv</div>
                  </a>)
})}
</>

)

}
